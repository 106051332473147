import Script from "next/script";
import { GoogleTagManager, sendGTMEvent } from "@next/third-parties/google";

import { env } from "~/env.mjs";
import { logFirebaseEvent } from "../auth/firebase-app";
import { analytics } from "./AnalyticsProvider";

// lib/gtm.ts
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const GTM_ID = env.NEXT_PUBLIC_GTM_ID;

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== "undefined" && GTM_ID) {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    });
  } else {
    console.log({
      event: "pageview",
      page: url,
    });
  }
};

export default function GTM() {
  return (GTM_ID && <GoogleTagManager gtmId={GTM_ID} />) || null;
}

export const trackEvent = (event: string, data: Record<string, any>) => {
  logFirebaseEvent(event, data);
  analytics.trackEvent(event, data);
  if (GTM_ID) {
    return sendGTMEvent({ event, data });
  }
};
