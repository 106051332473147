"use client";

import { useEffect } from "react";

import { useStore } from "~/hooks/store";
import { setUserToSentry } from "../monitoring";

export default function ClientSideMonitoring() {
  const loggedUser = useStore((s) => s.loggedUser);

  useEffect(() => {
    if (loggedUser) {
      setUserToSentry({
        userId: loggedUser.uid,
        email: loggedUser.email,
      });
    }
  }, [loggedUser]);

  return null;
}
