"use client";

//https://upmostly.com/next-js/how-to-upload-a-file-to-s3-with-next-js
//https://next-s3-upload.codingvalue.com/setup
import * as React from "react";
import Image from "next/image";
import { useLocale, useTranslations } from "next-intl";

import { Button, Card, CardContent, Input, SpinnerIcon } from "@acme/ui";
import { logger } from "@acme/utils";

import { createUploadShape } from "~/app/api/file-access/createUpload";

type UploadToS3Props = {
  imageUploadCallback: (
    res: { key: string; url: string; photoId: string } | null,
    error?: string | null,
  ) => void;
  imageUploadStatus?: (running: boolean) => void;
  fileSelectedCallback?: (file: File) => void;
} & { token?: string } & { patientId: string };

export function UploadToS3(props: UploadToS3Props) {
  const [loading, setLoading] = React.useState(false);

  const t = useTranslations("uploadFile");
  const locale = useLocale();

  const uploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]!;

    props.fileSelectedCallback?.(file);

    const filename = file.name;
    const fileType = file.type;

    logger.info("filename ", filename, fileType);

    const pathName =
      `/api/file-access` + (props.token ? `/${props.token}` : "");
    try {
      props.imageUploadStatus?.(true);
      setLoading(true);
      const res = await fetch(
        `${pathName}?${new URLSearchParams({
          file: filename,
          fileType: fileType,
          locale: locale || "en",
        })}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            patientId: props.patientId,
          }),
        },
      );

      if (!res.ok) throw new Error(`Error: ${res.status} '${res.statusText}'`);

      const { signedUrl, publicUrl, key, photoId } = createUploadShape.parse(
        await res.json(),
      );

      const upload = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": fileType,
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (upload.ok) {
        console.log("Success.");
        props.imageUploadCallback({ key: key, url: publicUrl, photoId });
      } else {
        console.error("Upload failed.");
        props.imageUploadCallback(null, "Upload failed.");
      }
    } catch (error: any) {
      console.error(error);
      props.imageUploadCallback(null, error.message);
    } finally {
      setLoading(false);
      props.imageUploadStatus?.(false);
    }
  };

  return (
    <div className="max-w grid w-full items-center gap-1.5">
      <Card>
        <CardContent className="flex aspect-square flex-col items-center justify-center p-6">
          <span className="text-sm font-semibold">{t("message.title")}</span>
          <ul className="m-4 list-disc text-xs">
            {([1, 2, 3, 4, 5] as const).map((v) => (
              <li key={v}>{t(`message.list.${v}`)}</li>
            ))}
          </ul>

          <Image src="/static/faces.png" width={340} height={340} alt={""} />
        </CardContent>
      </Card>

      <Input
        id="picture"
        type="file"
        accept="image/*"
        onChange={uploadPhoto}
        className="hidden"
        capture="environment"
        data-testid="file-upload"
      />

      <Button
        disabled={loading}
        onClick={() => {
          document?.getElementById("picture")?.click();
        }}
      >
        {loading ? <SpinnerIcon className="h-6 w-6 text-center" /> : null}
        {t("choosePicture")}
      </Button>
    </div>
  );
}

export default UploadToS3;
