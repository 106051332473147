"use client";

import { useState } from "react";
import { useRouter } from "next/navigation";
import * as Sentry from "@sentry/nextjs";
import { useTranslations } from "next-intl";

import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acme/ui";
import { cn, logger } from "@acme/utils";

import { setOnboardingUserType } from "~/app/_actions/onboarding";
import UploadToS3 from "~/features/upload-file/component/file";
import { trackEvent } from "~/lib/analytics/gtm";

type InitialUploadProps = {
  onboardingType?: "personal" | "professional";
};

export default function InitialUpload({ onboardingType }: InitialUploadProps) {
  const t = useTranslations("patient");

  const [userType, setUserType] = useState<
    "personal" | "professional" | undefined
  >(onboardingType);
  const [userTypeTemp, setUserTypeTemp] = useState<
    "personal" | "professional"
  >();

  const router = useRouter();

  return (
    <Dialog defaultOpen={true} open={true}>
      <DialogContent
        className="sm:max-w-[425px]"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        {!userType ? (
          <>
            <DialogHeader>
              <DialogTitle>{t("survey.title")}</DialogTitle>
            </DialogHeader>
            <Card>
              <CardContent className="space-y-4 pt-6">
                <Button
                  variant={userTypeTemp === "personal" ? "default" : "outline"}
                  className={cn(
                    "h-auto w-full justify-start whitespace-normal py-4 text-left",
                    "focus-visible:ring-0 focus-visible:ring-offset-0",
                  )}
                  onClick={() => setUserTypeTemp("personal")}
                >
                  {t("survey.personal")}
                </Button>
                <Button
                  variant={
                    userTypeTemp === "professional" ? "default" : "outline"
                  }
                  className={cn(
                    "h-auto w-full justify-start whitespace-normal py-4 text-left",
                    "focus-visible:ring-0 focus-visible:ring-offset-0",
                  )}
                  onClick={() => setUserTypeTemp("professional")}
                >
                  {t("survey.professional")}
                </Button>
              </CardContent>
              <CardFooter>
                <Button
                  className="mt-4 w-full"
                  onClick={() => {
                    setUserType(userTypeTemp);
                    userTypeTemp &&
                      setOnboardingUserType(userTypeTemp).catch((e) =>
                        Sentry.captureException(e),
                      );
                  }}
                  disabled={!userTypeTemp}
                >
                  {t("survey.continueBtn")}
                </Button>
              </CardFooter>
            </Card>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>{t("form.addPhoto")}</DialogTitle>
            </DialogHeader>
            <UploadToS3
              imageUploadCallback={(res, error) => {
                logger.info("pa imageUploadCallback", res, error);
                /// When this is called I need to close the dialog
                if (res) {
                  trackEvent("patient", {
                    id: "onboarding-patient-id",
                    action: "photo_uploaded",
                  });
                  const photoId = res.photoId;
                  /// navigate to /measure/<photoId>
                  router.push(`/measure/${photoId}`);
                }
              }}
              fileSelectedCallback={(file) => {
                logger.info("fileSelectedCallback", file);
              }}
              imageUploadStatus={(running) => {
                logger.info("imageUploadStatus", running);
              }}
              patientId="onboarding-patient-id"
            />
          </>
        )}
        <DialogFooter>
          {/* <Button type="submit" disabled={disabled}>Save changes</Button> */}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
