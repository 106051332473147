"use client";

import dynamic from "next/dynamic";

// Ecmascript file had an error
//    8 | import PatientList from "./patient-list";
//    9 |
// > 10 | const MainTour = dynamic(() => import("./main-tour"), { ssr: false });
//      |                  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//   11 |
//   12 | export default async function PatientListPage() {
//   13 |   const t = await getTranslations("patient");

// `ssr: false` is not allowed with `next/dynamic` in Server Components. Please move it into a client component.

const MainTour = dynamic(() => import("./main-tour"), { ssr: false });

export default function MainTourWrapper() {
  return <MainTour />;
}
