class ErrorHttp extends Error {
  protected status: number;
  constructor(message: string, status: number, options?: ErrorOptions) {
    super(message, options);
    this.status = status;
  }
}

export class ErrorNotFound extends ErrorHttp {
  constructor(message = "Not Found", options?: ErrorOptions) {
    super(message, 404, options);
    this.name = "ErrorNotFound";
  }
}

export class ErrorUnauthorized extends ErrorHttp {
  constructor(message = "Unauthorized", options?: ErrorOptions) {
    super(message, 401, options);
    this.name = "ErrorUnauthorized";
  }
}

export class ErrorForbidden extends ErrorHttp {
  constructor(message = "Forbidden", options?: ErrorOptions) {
    super(message, 403, options);
    this.name = "ErrorForbidden";
  }
}
