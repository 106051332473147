import * as Sentry from "@sentry/nextjs";

export function setUserToSentry({
  userId,
  email,
}: {
  userId: string;
  email?: string;
}) {
  Sentry.setUser({ id: userId, email: email });
}
