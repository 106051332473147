"use client";

// Import FirebaseAuth and firebase.
import React from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { inMemoryPersistence, setPersistence, type User } from "firebase/auth";
import firebase from "firebase/compat/app";
import { type auth } from "firebaseui";
import Cookies from "js-cookie";

import "firebase/compat/auth";

import * as Sentry from "@sentry/nextjs";
import { useTranslations } from "next-intl";

import { sessionLoginResult } from "@acme/types";
import { LoadingSpinner } from "@acme/ui";
import { logger } from "@acme/utils";

import { sanitizeReturnPath } from "~/features/auth/return-to";
import { useStore } from "~/hooks/store";
import { useAnalytics } from "~/lib/analytics/AnalyticsContextState";
import { trackEvent } from "~/lib/analytics/gtm";
import { logIn, logOut } from "~/lib/auth/auth-component";
import { fbAuth } from "~/lib/auth/firebase-app";
import StyledFirebaseAuth from "./styled-firebase-auth";

export default function AuthComponent() {
  const setLoggedUser = useStore((s) => s.setLoggedUser);

  const t = useTranslations("auth.login");
  const tCommon = useTranslations("common");
  const analytics = useAnalytics();

  const [loading, setLoading] = React.useState(true);

  const router = useRouter();

  const searchParams = useSearchParams();

  // Configure FirebaseUI.
  const uiConfig: auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: t("signUpWithGoogle"),
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: t("signUpWithEmail"),
      },
    ],

    siteName: "OptiGrid",
    callbacks: {
      signInFailure: (error) => {
        console.log("error ", error);
      },
      signInSuccessWithAuthResult: (
        authResult: { user: User },
        redirectUrl,
      ) => {
        logger.debug("authResult", authResult);
        logger.info("redirectUrl", redirectUrl);

        const user = authResult.user;

        logIn({
          ...user,
          displayName: user.displayName || "",
          email: user.email || "",
          photoURL: user.photoURL || "",
        });

        analytics.setUser(authResult.user.uid, {
          email: authResult.user.email,
          username: authResult.user.displayName,
        });
        analytics.trackEvent("login");

        authResult.user
          .getIdToken()
          .then(async (idToken: string | undefined) => {
            await setPersistence(fbAuth, inMemoryPersistence);
            //// ^

            const signOutPromise = fbAuth.signOut();

            if (!idToken) throw new Error("No idToken");

            // Session login endpoint is queried and the session cookie is set.
            // CSRF protection should be taken into account.
            // ...

            const csrfToken = Cookies.get("csrfToken");
            console.log("before postIdTokenToSessionLogin");

            const [user] = await Promise.all([
              postIdTokenToSessionLogin(idToken, csrfToken),
              signOutPromise.catch((e) => Sentry.captureException(e)),
            ]);

            return user;
          })
          .then((r) => {
            setLoggedUser(r.user);
            const returnTo = sanitizeReturnPath(
              searchParams.get("return-to"),
              "/",
            );
            console.log("Before redirect setLoggedIn", returnTo);

            router.replace(returnTo);
          })
          .catch(async (error) => {
            Sentry.captureException(error);
            await logOut().catch((e) => Sentry.captureException(e));
          });

        return false;
      },
    },
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner
          className="m-4 text-center"
          loadingText={tCommon("loading")}
        />
      ) : null}
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={fbAuth}
        uiCallback={(_ui) => {
          setLoading(false);
        }}
      />
      <div className="max-w-64 text-center text-sm text-gray-500">
        By continuing, you agree to Optgird's{" "}
        <a href="https://www.optigrid.io/terms-of-service/" target="_blank">
          Terms of Service
        </a>
        ,{" "}
        <a href="https://www.optigrid.io/privacy-policy/" target="_blank">
          Privacy Policy
        </a>
        .
      </div>
    </>
  );
}

async function postIdTokenToSessionLogin(
  idToken: string,
  csrfToken: string | undefined,
) {
  const url = "/api/session-login";
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ idToken, csrfToken }),
  });

  if (!res.ok) {
    throw new Error(`Session token failed. ${res.status}: ${res.statusText}`);
  }

  return sessionLoginResult.parse(await res.json());
}
